@import '../../../../../../app/global-styles/constants';

.Users {
  :global(.v-collapse) {
    padding-top: 0;
  }

  :global(.ant-collapse-item:first-child) {
    padding-top: 0;
  }
}

.User {
  width: 448px;
}

.Collapse {}

.Bold {
  font-weight: $bold-font-weight;
  color: $color-black;
}

.Block16 {
  margin-top: 16px;
}

.Block32 {
  margin-top: 32px;
}

.ButtonGreen, .ButtonGreen:hover, .ButtonGreen:active {
  background: $color-green-we!important;
  color: $color-white!important;
}

.Controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.UserDetails {
  margin-top: 16px;
}

.Control {
  display: inline-block;
  color: $color-dark-sky-blue;
  font-weight: $bold-font-weight;
  cursor: pointer;
  vertical-align: middle;
}

.ControlDisabled {
  @extend .Control;
  color: $color-gray;
  cursor: default;
}

.Control:not(:first-child) {
  margin-left: 16px;
}

.inline {
  display: inline-block;
}

.inline:not(:first-child) {
  margin-left: 32px;
}

.usersList {
  width: 428px;
}

.usersListRow {
  font-weight: 500;
  font-size: 13px;
  color: $color-black;
  border-bottom: 1px solid $color-light-gray;
}

.usersListUsername {
  margin-top: 32px;
  margin-bottom: 32px;
}
