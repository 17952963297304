@import '../../../app/global-styles/constants';

:global(.ant-menu-item) {
  padding-left: 40px !important;
}

:global(.ant-menu-item .v-icon) {
  font-size: inherit !important;
  left: -16px;
}

:global(.v-selected-menu-line) {
  border-bottom-color: $color-green-we!important;
}

.NavigationMenu {
  display: flex;
  justify-content: flex-end;
}

.menu {
  border-right: none;
  width: 208px;
  max-width: 3 * $row-width + 2 * 32px;

  a {
    display: inline-block;
  }
}

.EmptyMenuItem {
  cursor: default;
}
