@import '../../app/global-styles/constants';

.SearchContainer {
}

.CompaniesPage {
  .countText {
    margin-top: 24px;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.23;
    height: 16px;
  }

  .top {
    color: $color-black;
    position: sticky;
    top: 0;
    padding: $application-top-padding 16px 16px 16px;
    margin-bottom: 18px;
    background: #fff;
    z-index: 10;
  }

  .welcomeInfo {
    width: 400px;
    color: $color-black;
    font-weight: 500;
  }

  .block32 {
    margin-top: 32px;
  }

  .link {
    color: $color-dark-sky-blue;
    font-weight: $bold-font-weight;
    cursor: pointer;
  }

  .topShadow {
    @extend .top;
    box-shadow: 0 16px 10px -12px rgba(10, 6, 6, 0.25);
  }

  .filters {
    display: flex;
    padding-bottom: 16px;

    & > * {
      font-weight: 500;
      &:not(:first-child) {
        padding-left: 16px;
      }

      button {
        font-weight: 500;
      }
    }
  }
}

:global(.ant-input-group-addon) {
  color: $color-black !important;
}

:global(.tags-filter-container) {
  position: relative;
}

:global(.tags-filter-container .v-context-menu.ant-popover-placement-bottomLeft) {
  top: 52px!important;
}

:global(.tags-filter-container .ant-popover-inner-content) {
  > div > div {
    max-height: 400px!important;
  }
}
