.CountInfo {
  font-weight: 500;
}

.Block16 {
  margin-top: 16px;
}

.Block24 {
  margin-top: 24px;
}
