@import '../../../../../../app/global-styles/constants';

.FiltersBlock {
  min-width: 850px;
  padding-bottom: 16px;
}

.Filter {
  display: inline-block;
}

.Filter:not(:first-child) {
  margin-left: 16px;
}

.List {
  margin-top: 32px;
  color: $color-black;
}

.ListItem {

}

.Bold {
  font-weight: $bold-font-weight;
  color: $color-black;
}

.LicenseListSpinner {
  margin-top: 15px !important;
}

.ButtonGreen, .ButtonGreen:hover, .ButtonGreen:active {
  background: $color-green-we!important;
  color: $color-white!important;
}

:global(.v-list-item__activated) {
  background: rgba(224, 224, 224, 0.25);
}

:global(.v-list-item__available) {
  background: rgba(224, 224, 224, 0.25);
  .v-icon {
    color: gray;
  }
}

:global(.v-list-item__expired ) {
  background: rgba(141, 141, 141, 0.1);
  opacity: 0.4;
}

:global(.list-item-col:nth-child(2) .v-tx-list__data-col__value) {
  // font-weight: 500;
}
