@import '../../../../app/global-styles/constants';

.UserDetails {
  width: $content-width;
  padding-bottom: 32px;

  .Block48 {
    margin-top: 48px;
  }

  .Block32 {
    margin-top: 32px;
  }

  .Block16 {
    margin-top: 16px;
  }

  .CheckboxRoleContent {
    color: $color-black;
    margin-top: $row-height;
  }

  .CheckboxRoleDescription {
    margin-top: -8px;
    font-size: 11px;
    color: $color-gray;
  }

  .Title {
    font-weight: $bold-font-weight;
    color: $color-black;
  }

  .ButtonsBlock {
    margin-top: $row-height * 2;
    > span:not(:first-child) {
      margin-left: 16px;
    }
  }

  .SpinContainer {
    display: inline-block;
    margin-left: 32px;
    vertical-align: middle;
  }
}
