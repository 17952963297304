@import '../../../app/global-styles/constants';

.CountdownCircle {
  position: relative;
  margin: auto;
  height: 40px;
  width: 40px;
  text-align: center;

  @keyframes countdown {
    from {
      stroke-dashoffset: 0;
    }
    to {
      stroke-dashoffset: 113px;
    }
  }

  .number {
    color: black;
    display: inline-block;
    line-height: 40px;
  }

  .svg {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    transform: rotateY(-180deg) rotateZ(-90deg);
  }

  .svg circle {
    stroke-dasharray: 113px;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    stroke-width: 2px;
    stroke: $color-green-we;
    fill: none;
    animation: countdown linear infinite forwards;
    animation-duration: 5s;
  }
}