@import '../../app/global-styles/constants';

.CompaniesList {
  padding: 0 16px;
  margin-bottom: 32px;

  .spinnerWrapper {
    display: flex;
    justify-content: center;
    padding: 40px 0;
    transform: scale(1.5);
  }
}

:global(.ant-row) {
  margin-left: 0!important;
  margin-right: 0!important;
}

:global(.v-tx-list__data-col__value) {
  color: $color-black;
}

:global(.v-tx-list__data-col__caption) {
  font-weight: $bold-font-weight;
}
