@import '../../../app/global-styles/constants';

.Content {
}

:global {
  .ant-collapse-header .arrow.v-icon {
    line-height: inherit !important;
  }

  .v-suggest-options {
    max-height: 250px!important;
  }
}
