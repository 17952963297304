@import '../../../app/global-styles/constants';

.InfoMessage {
  display: flex;
  align-items: baseline;

  .done {
    color: $color-green;
  }

  .info {
    color: $color-gray;
  }

  .warning {
    color: $color-yellow;
  }

  .content {
    margin-left: 16px;
    color: $color-black;
  }
}
