.SelectCompanyContainer {
  width: 312px;
}

.SuggestWrapper {
  margin-top: 24px;
}

.ButtonsContainer {
  display: flex;
  justify-content: space-between;

  > span:first-child {
    margin-right: 24px;
  }
}

.ButtonClose:not(.ant-btn:active) {
  color: #525252!important;
}
