@import '../../../app/global-styles/constants';

.FakeInput {
  position: absolute;
  font-weight: 400;
}

.SearchInput {
  font-weight: 500;
  position: relative;
  /*height: 32px;*/
}

.SearchInputActive {
  z-index: 21;
}

.OptionsList {
  position: absolute;
  z-index: 20;
  background-color: white;
  width: 100%;
  min-width: 120px;
  padding: 8px;
  box-shadow: 0 8px 12px 0 rgba(10, 6, 6, 0.1);
  overflow-y: scroll;
  max-height: 256px;
  margin-bottom: 120px;

  .ListItem {
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .UnselectableItem {}

  .ListItem:not(.UnselectableItem) {
    cursor: pointer;
  }

  .ListItemActive {
    @extend .ListItem;
    background-color: $color-green-we;
    color: $color-white;
  }

  .ListItemValue {
    padding-left: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
  }
}

:global {
  .v-input-spinner {
    top: 7px!important;
    right: 14px!important;
  }
}
