@import '../../../app/global-styles/constants';

.ChangePassword {
  .link {
    color: $color-dark-sky-blue;
    font-weight: $bold-font-weight;
    cursor: pointer;
  }

  .inline {
    display: inline-block;
  }

  .inline:not(:first-child) {
    margin-left: 16px;
  }
}