@import '../../../../app/global-styles/constants';

.Container {
  display: flex;
}

.Content {
  width: 448px;
}

.Bold {
  font-weight: $bold-font-weight;
  color: $color-black;
}

.ContentButtonsContainer {
  margin-top: 16px;
}

.ContentButton {
  margin-bottom: 48px;
}

.TagsSelectorContainer {
  width: 448px;
}

.DetailsContainer {
  margin-top: 24px;
}

.InfoContainer {
  margin-left: 84px;
  width: 384px;
}

.FormItem:not(:first-child) {
  margin-top: 10px;
}
