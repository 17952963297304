@import '../../../app/global-styles/constants';

.Sidebar {
  position: sticky;
  top: 0;
  padding-top: 112px;
}

.logoWrapper {
  display: flex;
  justify-content: flex-end;
  height: 73px;
}

.logo {
  font-size: 28px;
  font-weight: bold;
  letter-spacing: -1px;
  color: $title-color;
  margin-right: 64px;
  margin-bottom: 44px;
}
