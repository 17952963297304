.WelcomeScreen {
  display: flex;
  flex-direction: row;

  .content {
    width: 448px;
  }

  .info {
    margin-left: 112px;
  }
}