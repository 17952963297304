$animationTime: 0.45s;

@keyframes buttonsFadeIn {
  from {
    opacity: 0;
    bottom: -48px;
  }
  to   {
    opacity: 1;
    bottom: 32px;
  }
}

.FixedButtonsContainer {
  position: fixed;
  right: 32px;
  bottom: 32px;
  transition: bottom $animationTime;
  z-index: 1;

  :global(.v-btn-wrapper:not(:first-child)) {
    margin-left: 16px;
  }
}

:global(.v-side-panel) {
  &-open {
    .FixedButtonsContainer {
      animation: buttonsFadeIn $animationTime;
    }
  }

  &-close {
    .FixedButtonsContainer {
      display: none;
    }
  }
}
