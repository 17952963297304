@import '../../../app/global-styles/constants';

.Authorization {
  color: $color-black;
  font-weight: normal;

  .block32 {
    margin-top: 32px;
  }

  .title {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: -1px;
    color: $title-color;
    padding-bottom: 32px;
  }

  .languageSelect {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .link {
    color: $color-dark-sky-blue;
    font-weight: $bold-font-weight;
    cursor: pointer;
  }

  .inline {
    display: inline-block;
  }

  .inline:not(:first-child) {
    margin-left: 32px;
  }
}
