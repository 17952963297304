@charset "UTF-8";

@import '../../../../app/global-styles/constants';

.Content {
  margin-top: 32px;
}

.Bold {
  font-weight: $bold-font-weight;
  color: $color-black;
}

.Item {
  display: inline-block;
}

.BasicItem {
  @extend .Item;
  width: 264px;
}

.ItemsContainer {
  margin-top: 16px;
  .Item:not(:last-child) {
    margin-right: 48px;
  }
}

.Comment, .TagsContainer, .FullSizeItem {
  position: relative;
  width: 448px;
}

.ItemsContainer:last-child {
  margin-bottom: 60px;
}

.InputNumber {
  width: 96px;
}

.ItemInRow {
  display: inline-block;

  .ant-form-item {
    margin-bottom: 0!important;
  }
}

.ItemInRow:not(:first-child) {
  margin-left: 24px;
}

.GroupHeader {
  display: flex;
  justify-content: space-between;
  width: 623px;
  border-top: 1px solid #E0E0E0;
  padding-top: 32px;
}

.DeleteGroup {
  cursor: pointer;

  &:hover {
    color: $color-black;
    i {
      color: $color-black;
    }
  }

  > span:first-child {
    margin-right: 8px;
  }
}

:global(.v-date-picker-alt-content) {
  top: -118px;
  left: 148px;
}

:global(.v-form.ant-form .ant-form-item-control-wrapper .ant-form-item-control.has-error .ant-btn) {
  border: none;
}

:global(.v-form.ant-form .ant-form-item-control-wrapper .ant-form-item-control.has-success .ant-btn ) {
  border: none;
}

:global(.v-date-picker-alt .v-date-picker-alt-content) {
  z-index: 100;
}
