@import '../../../../app/global-styles/constants';

.Button:not(:first-child) {
  margin-left: 16px;
}

.Content {
  margin-top: $row-height * 2;
  margin-bottom: $row-height * 12;
  width: 448px;
  padding-left: 16px;
  padding-right: 16px;

  :global(.ant-form-item) {
    margin-bottom: 0!important;
  }
}

.Item {
  margin-top: 24px;
}

.Title {
  color: $color-black;
  font-weight: $bold-font-weight;
}
