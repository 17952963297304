@import '../../../../app/global-styles/constants';

.ActivateScreen {
  width: 448px;

  .inline {
    display: inline-block;
  }

  .inline:not(:first-child) {
    margin-left: 16px;
  }
}