@import '../../../../app/global-styles/constants';

.Content {
  display: flex;
}

.Buttons > span:not(:first-child) {
  margin-left: 20px;
}

.Info {
  margin-top: 32px;
  width: 448px;
}

.CompanyInfo {
  margin-top: 32px;
}

.InfoMessage {
  margin-top: 48px;
}

.Steps {
  margin-left: 96px;
  margin-top: 136px;
}

.Bold {
  font-weight: $bold-font-weight;
  color: $color-black;
}