$row-width: 48px;
$row-height: 16px;
$font-path: '../resources/fonts';
$font-size-small: 11px;
$color-black: #0a0606;
$title-color: #3b3838;
$color-gray: #8d8d8d;
$color-light-gray: #E0E0E0;
$color-dark-sky-blue: #4ab0e0;
$color-white: #ffffff;
$color-green: #4ece3d;
$color-green-we: #00A87A;
$color-marine: rgb(8, 29, 82);
$color-yellow: #F8B700;
$application-top-padding: 112px;
$content-width: 448px;
$bold-font-weight: 500;
