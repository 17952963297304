@import '../../../../app/global-styles/constants';

.RestoreScreen {
  width: 448px;
}

.block16 {
  margin-top: 16px;
}

.block32 {
  margin-top: 32px;
}

.bold {
  font-weight: 500;
  color: $color-black;
}
