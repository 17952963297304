@import '../../app/global-styles/constants';

.SettingsPage {
  width: 448px;
  padding-top: 112px;
  color: $color-black;

  .Block16 {
    margin-top: $row-height;
  }

  .Block32 {
    margin-top: $row-height * 2;
  }

  .Bold {
    font-weight: $bold-font-weight;
    color: $color-black;
  }

  .Link {
    color: $color-dark-sky-blue;
    font-weight: $bold-font-weight;
    cursor: pointer;
  }

  .SeparatorLine {
    background: $color-light-gray;
    height: 1px;
    width: 100%;
  }
}
